var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "step_role" },
    [
      _c(_vm.isMobile ? "FormM" : "FormPc", {
        ref: "roleComponent",
        tag: "components",
        attrs: {
          serverList: _vm.serverList,
          showCountry: _vm.showCountry,
          showNoRole: _vm.showNoRole,
          "has-roles": _vm.hasRoles,
        },
        on: {
          confirm: function ($event) {
            return _vm.$emit("confirm")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }