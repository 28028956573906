<template>
    <div class="xy-common-title">
        <span class="name">{{ name }}</span>
    </div>
</template>

<script>

export default {
    props: {
        name: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>