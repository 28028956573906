<template>
  <div>
    <Popup :title="$sys('account.pleaseSelectRole')" :value='visible' @close='visible = false'>
      <StepRole :isOpen='visible' :showCountry='false' :showNoRole='true' :isEdit='true' @confirm='visible = false' />
    </Popup>
  </div>
</template>

<script>
import StepRole from '@/pages/xy/components/StepRole'
import Popup from '@/components/Popup'
import { mapGetters } from 'vuex'

export default {
  components: {
    StepRole,
    Popup
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('close', val)
      }
    }
  }
}
</script>

<style lang='scss' scoped></style>