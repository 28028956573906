<template>
  <div>
    <van-form ref='form' :submit-on-enter='false' v-if='hasRoles'>

      <van-field
        v-model='roleName'
        is-link
        readonly
        :label="$sys('account.roleName')"
        :placeholder="$sys('warn.roleEmpty')"
        @click='visibleRole = true'
      />

      <van-field
        v-if='showCountry'
        v-model='countryName'
        is-link
        readonly
        :label="$sys('account.country')"
        :placeholder="$sys('form.placeholder.select', {name: ''})"
        @click='visibleCountry = true'
      />

      <MobilePopRoleSelect
        :source='roleList'
        :visible.sync='visibleRole'
        :only-show='true'
        @close='close()'
        @confirm='handleRoleConfirm'
      />

      <van-popup get-container='body' v-model='visibleCountry' round position='bottom'>
        <van-picker
          show-toolbar
          :columns='countryLangList'
          value-key='name'
          @confirm='onConfirmCountry'
          @cancel='visibleCountry = false'
        />
      </van-popup>

      <van-button
        block
        class='btn_submit'
        type='info'
        color='#f3981d'
        :loading='isBtnLoading'
        @click='onSubmit'
      >
        {{ $sys('btn.confirm') }}
      </van-button>

    </van-form>

    <div v-else>
      <div class='tips_no_roles'>{{ $sys('account.noRoles', { name: `【${nickname}】` }) }}</div>
      <van-button
        block
        class='btn_submit'
        type='info'
        color='#f3981d'
        :loading='isBtnLoading'
        @click='onSubmit'
      >
        {{ $sys('btn.confirm') }}
      </van-button>
    </div>
  </div>
</template>

<script>
import { Form, Field, Button, Popup, Picker } from 'vant'
import mixin from './mixin'
import MobilePopRoleSelect from '@/components/MobilePopRoleSelect'

export default {
  mixins: [mixin],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    MobilePopRoleSelect
  },
  data() {
    return {
      visibleServer: false,
      visibleRole: false,
      visibleCountry: false
    }
  },
  computed: {
    countryName() {
      const obj = this.countryLang.find(item => item.value === this.country)
      return obj?.country + '(' + obj?.value + ')'
    },
    countryLangList() {
      return this.countryLang.map(item => ({
        ...item,
        name: item.country + '(' + item.value + ')'
      }))
    }
  },
  methods: {
    onConfirmCountry(info) {
      this.visibleCountry = false
      this.countryVal = info.value
    },
    handleRoleConfirm({ roleName, roleVal, serverName, serverVal }) {
      this.serverName = serverName
      this.serverVal = serverVal
      this.roleName = roleName
      this.roleVal = roleVal
    }
  }
}
</script>

<style lang='scss' scoped>

.van-field {
  width: 500px;
  margin: 40px auto;
  border-radius: 10px;

  .van-field__control {
    text-align: right;
  }

  .van-field__control[type="text"]::placeholder {
    text-align: right;
  }
}

.tips_no_roles {
  font-size: 26px;
  line-height: 40px;
  color: #666666;
  text-align: center;
  padding: 50px;
}

.btn_submit {
  width: 400px;
  height: 80px;
  background-color: #FC892E;
  border: none;
  margin: 40px auto 0;
  font-weight: bold;
  font-size: 32px;
}
</style>