var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "role_info flex-start" },
    [
      !_vm.isMobile
        ? _c("img", {
            staticClass: "game_icon",
            attrs: { src: _vm.gameIcon, alt: "" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "wrap" },
        [
          _c("Title", { attrs: { name: _vm.$sys("account.roleInfo") } }),
          _vm.isMobile
            ? _c(
                "div",
                { staticClass: "save_desktop" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn_save",
                      attrs: { type: "text" },
                      on: { click: _vm.saveDesktop },
                    },
                    [
                      _c("div", { staticClass: "flex-center" }, [
                        _c("span", { staticClass: "icon icon-save-mobile" }),
                        _c("span", { staticClass: "lang_name" }, [
                          _vm._v(_vm._s(_vm.$t("sys.btn.saveDesktopMobile"))),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isRole
            ? _c("div", { staticClass: "info flex-between" }, [
                _vm.isMobile
                  ? _c("img", {
                      staticClass: "game_icon",
                      attrs: { src: _vm.gameIcon, alt: "" },
                    })
                  : _vm._e(),
                _c("div", [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$sys("account.server")) + "："),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.userInfo.gameZoneName)),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$sys("account.roleID")) + "："),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.userInfo.gameRoleCode)),
                    ]),
                  ]),
                  _c("p", { staticClass: "role-name" }, [
                    _vm._v(_vm._s(_vm.$sys("account.roleName")) + "："),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.userInfo.gameRoleName)),
                    ]),
                  ]),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.handleChangeRole },
                  },
                  [_vm._v(_vm._s(_vm.$sys("account.changeRole")))]
                ),
              ])
            : _c("div", { staticClass: "welcome flex-start" }, [
                _vm.isMobile
                  ? _c("img", {
                      staticClass: "game_icon",
                      attrs: { src: _vm.gameIcon, alt: "" },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "msg" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$sys("account.welcome")))]),
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.login("sign_up")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$sys("btn.login")))]
                  ),
                ]),
              ]),
        ],
        1
      ),
      _c("MobilePopRoleSelect", {
        attrs: { "lazy-request": "", visible: _vm.isPopRole },
        on: {
          "update:visible": function ($event) {
            _vm.isPopRole = $event
          },
          close: function ($event) {
            return _vm.close()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }