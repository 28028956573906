var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Popup",
        {
          attrs: {
            title: _vm.$sys("account.pleaseSelectRole"),
            value: _vm.visible,
          },
          on: {
            close: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c("StepRole", {
            attrs: {
              isOpen: _vm.visible,
              showCountry: false,
              showNoRole: true,
              isEdit: true,
            },
            on: {
              confirm: function ($event) {
                _vm.visible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }